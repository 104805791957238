import React, { useState } from 'react'

function Navbar() {
  const [activeMenu, setActiveMenu] = useState('')
  // 用 onClick 把 inline-style 加在 li.nav-item 上面

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light" id="navbar">
      <a className="navbar-brand" href="/">
        數位優學習實驗室
      </a>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav mr-auto">
          <li
            className="nav-item"
            onClick={() => {
              const setHomeAsState = 'Home'
              setActiveMenu(setHomeAsState)
              console.log(activeMenu)
            }}
            style={activeMenu === 'Home' ? { color: '#000 !important' } : {}}
          >
            <a href="/" className="nav-link">
              Home-林珊如老師
            </a>
          </li>
          <li
            className="nav-item"
            onClick={() => {
              setActiveMenu('students')
            }}
            style={
              activeMenu === 'students' ? { color: '#000 !important' } : {}
            }
          >
            <a href="/Students" className="nav-link">
              Lab成員
            </a>
          </li>
          <li
            className="nav-item"
            onClick={() => {
              setActiveMenu('admin')
            }}
            style={
              activeMenu === 'students' ? { color: '#000 !important' } : {}
            }
          >
            <a href="/Admin" className="nav-link">
              管理
            </a>
          </li>
          {/* <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              href="#"
              role="button"
              data-toggle="dropdown"
              aria-expanded="false"
            >
              Dropdown
            </a>
            <div className="dropdown-menu">
              <a className="dropdown-item" href="#">
                Action
              </a>
              <a className="dropdown-item" href="#">
                Another action
              </a>
              <div className="dropdown-divider"></div>
              <a className="dropdown-item" href="#">
                Something else here
              </a>
            </div>
          </li> */}
        </ul>
        {/* <form className="form-inline my-2 my-lg-0">
          <input
            className="form-control mr-sm-2"
            type="search"
            placeholder="Search"
            aria-label="Search"
          />
          <button
            className="btn btn-outline-success my-2 my-sm-0"
            type="submit"
          >
            Search
          </button>
        </form> */}
      </div>
    </nav>
  )
}

export default Navbar
