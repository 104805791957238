import { PublicationData } from './data/PublicationData'
import './Mainpage.css'
import { React, useState } from 'react'
import { NstcProj } from './data/NstcProj'

function Mainpage() {
  const [clicked, setClicked] = useState('bio')
  const handleClick = (content) => {
    setClicked(content)
  }

  return (
    <div className="cont">
      <div className="row-flex">
        <div className="info-container">
          <h3 className="prof-name">林珊如</h3>
          <div className="infos">國立陽明交通大學教育研究所 教授</div>
          <div className="infos">數位優學習實驗室</div>
          <div className="infos">電子郵件：sunnylin@nycu.edu.tw</div>
          <div className="infos">
            辦公室電話：03-5712121#31714 or 58066(助理電話)
          </div>
          <div className="infos">
            專長：
            <div className="hashtag-flexer">
              <a
                href="https://www.google.com/search?q=教育心理學"
                target="_blank"
                rel="noreferrer"
              >
                <div className="hashtag">#教育心理學</div>
              </a>
              <a
                href="https://www.google.com/search?q=數位科技輔助教學"
                target="_blank"
                rel="noreferrer"
              >
                <div className="hashtag">#數位科技輔助教學</div>
              </a>
              <a
                href="https://www.google.com/search?q=數位不學習"
                target="_blank"
                rel="noreferrer"
              >
                <div className="hashtag">#數位不學習</div>
              </a>
            </div>
          </div>
        </div>
        <div className="img-container">
          <img src="./sunnylin.jpeg" className="main-photo" alt="sjlin"></img>
        </div>
      </div>
      <div className="flex-sel">
        <div
          className="selector selector-bio"
          onClick={() => handleClick('bio')}
          style={clicked === 'bio' ? { background: '#DABEA7' } : {}}
        >
          自傳
        </div>
        <div
          className="selector selector-edu"
          onClick={() => handleClick('edu-career')}
          style={clicked === 'edu-career' ? { background: '#DABEA7' } : {}}
        >
          學歷
        </div>
        <div
          className="selector selector-class"
          onClick={() => handleClick('classesAndAwards')}
          style={
            clicked === 'classesAndAwards' ? { background: '#DABEA7' } : {}
          }
        >
          授課與榮譽
        </div>
        <div
          className="selector selector-pub"
          onClick={() => handleClick('publication')}
          style={clicked === 'publication' ? { background: '#DABEA7' } : {}}
        >
          論文
        </div>
        <div
          className="selector selector-nstc d-none d-md-block"
          onClick={() => handleClick('nstc')}
          style={clicked === 'nstc' ? { background: '#DABEA7' } : {}}
        >
          國科會(科技部)計畫
        </div>
        <div
          className="selector selector-nstc-rwd d-block d-md-none"
          onClick={() => handleClick('nstc')}
          style={clicked === 'nstc' ? { background: '#DABEA7' } : {}}
        >
          國科會計畫
        </div>
      </div>
      {clicked === 'bio' && (
        <div className="biography">
          <div className="bio-child">
            林珊如隸屬於教育心理學組，任教科目為「教育心理學研究」、「學習與認知」，2019及2004年榮獲科技部（前國科會）傑出研究獎，亦曾獲交大優良教學獎、特優導師獎。於2013-2015年擔任科技部科教國合司資訊教育學門召集人，帶領學門在新科技與課程改革的浪潮中發展，積極從事跨領域基礎研究。近五年發表23篇期刊論文(歷年來總計超過60篇)，4本中文專書或章節。有多篇論文累積的引用數量跨10年不墜，2015年以來的引用數更屢創高峰。
          </div>
          <div className="bio-child">
            在研究上試圖以數位科技輔助教學，引導學生積極主動、建構、互動學習，例如利用眼球追蹤探究線上問題解決與EFL閱讀數位圖文的注意力分佈、對比成功與失敗學習者閱讀圖文的交互參照歷程，提供個人化學習輔助的原則給老師與教材設計者，期許改善數位教學以一套應萬變，無法針對不同特性學生量身設計的困境。
          </div>
          <div className="bio-child">
            其次亦探索「數位不學習」(Anti-Digital
            Learning)現象，尋找大多數線上遊戲大學生玩家不愛學習的原因。目前發現遊戲玩家在虛擬世界真誠的交朋友，真實的付出苦工，獲得難得的樂趣，成癮的大學生玩家並不多見。大一學生的憂鬱是問題網路使用的長期前因，隨大學生活展開，平均憂鬱程度下降，問題網路使用也趨緩，趨緩的關鍵在於憂鬱下降的速度。而且從遊戲動機可以區分玩家的不適應程度，其中逃避遊戲動機可能是長期不適應的關鍵。本人的研究均以長期追蹤資料探究多個變項關聯的長期變化形態。
          </div>
          <div className="bio-child">
            研究是非常有趣的歷程，深入分析議題時，必須忍耐挫折突破無知、茫然與困難，只要設身處地去思考學生和老師的想法、憂愁與快樂，總能從無知到累積知識，而且這些知識都能對科技支援教學/學習有些許幫助。研究迷網的青少年時，我和團隊秉持同理心，站在他們的角度去體會他/她的感受，傾聽他們在現實世界的茫然、無聊，對比在虛擬世界的意氣風發。只要懷著成長心態(growth
            mindset)，在人生路上一定可持續進步。
          </div>
        </div>
      )}
      {clicked === 'edu-career' && (
        <div className="edu-n-career">
          學歷
          <ul>
            <li>美國南加州大學教育與心理博士</li>
            <li>國立政治大學教育系教育碩士</li>
          </ul>
          經歷
          <ul>
            <li>國立交通大學教育研究所/師資培育中心講座教授(2020.05~)</li>
            <li>
              國立交通大學教育研究所/師資培育中心特聘教授(2010.08 ~2020.04)
            </li>
            <li>國立交通大學教育研究所/師資培育中心教授(2003.08 ~ 2010.07)</li>
            <li>
              國立交通大學教育研究所/師資培育中心副教授(1996.08 ~ 2003.07)
            </li>
            <li>淡江大學教育學程副教授(1995.08 ~ 1996.07)</li>
            <li>淡江大學教育研究中心研究組主任(1987.09 ~ 1990.08)</li>
            <li>淡江大學教育研究中心助理研究員(1983.08 ~ 1984.07)</li>
            <li>淡江大學共同科講師(1983.08 ~ 1995.07)</li>
          </ul>
        </div>
      )}
      {clicked === 'classesAndAwards' && (
        <div className="classes">
          授課
          <ul>
            <li>教育心理</li>
            <li>認知心理學</li>
            <li>學習與認知</li>
          </ul>
          <div>榮譽</div>
          校內榮譽
          <ul>
            <li>89學年第一學期績效特優導師(2000)</li>
          </ul>
          校外榮譽
          <ul>
            <li>科技部傑出研究獎(2019)</li>
            <li>國科會傑出研究獎(2005)</li>
            <li>國科會傑出研究獎(2004)</li>
            <li>國科會甲種研究計劃，入圍傑出研究獎(2003)</li>
            <li>國科會甲種研究計劃，入圍傑出研究獎(2002)</li>
            <li>國科會甲種研究計劃，入圍傑出研究獎(2001)</li>
            <li>國科會甲種研究計劃，入圍傑出研究獎(2000)</li>
            <li>國科會甲種研究獎勵，入圍傑出研究獎(1999)</li>
            <li>國科會甲種研究獎勵，入圍傑出研究獎(1996)</li>
          </ul>
        </div>
      )}
      {clicked === 'publication' &&
        PublicationData.map((v, i) => {
          return (
            <div className="publication" key={v.sid}>
              {v.sid}. {v.author} {'('}
              {v.year}
              {')'}. {v.title}. {v.journal}. {v.remarks}
            </div>
          )
        })}
      {clicked === 'nstc' &&
        NstcProj.map((v, i) => {
          return (
            <div className="nstc" key={v.sid}>
              {v.proj_name}
            </div>
          )
        })}
    </div>
  )
}

export default Mainpage
