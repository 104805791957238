export const PublicationData = [
  {
    sid: 1,
    author: 'Chen, SK, Yang, YTC, Lin, C., & Lin, SSJ',
    year: 2022,
    title:
      'Dispositions of 21st-century skills in stem programs and their changes over time',
    journal:
      'International Journal of Science and Math Education. (Online First)',
    remarks: 'Doi: 10.1007/s10763-022-10288-0',
  },
  {
    sid: 2,
    author: 'Chen, S.-K., Liu, Y.-L. & Lin, SSJ',
    year: 2022,
    title:
      'Do grades make me big? School effects of math ability and math grades on math self-concept',
    journal: 'Educational Psychology, 42:5, 567-586',
    remarks: 'Doi: 10.1080/01443410.2021.2024512',
  },
  {
    sid: 3,
    author: 'Hsiao, J.-C., & Lin, SSJ',
    year: 2022,
    title:
      'How energy maintains social sustainability of teachers’ learning communities: New insights from a blended professional learning network',
    journal: 'Sustainability, 14, 3636',
    remarks: 'Doi: 10.3390/su14063636',
  },
  {
    sid: 4,
    author: 'Hsiao, JC, Chen, S.-K., Chen, W., & Lin, SSJ',
    year: '2022',
    title:
      'Developing a plugged-in class observation protocol in high-school blended STEM classes: Student engagement, teacher behaviors and student-teacher interaction patterns',
    journal: 'Computers & Education, 178, 104403',
    remarks: '(SSCI, IF=5.296, RF= 4/263)',
  },
  {
    sid: 5,
    author: 'Chang, Shan-Mei, Lin, SSJ*',
    year: '2019, Jul',
    title:
      'Online gaming motive profiles in late adolescence and the related longitudinal development of stress, depression, and problematic internet use',
    journal: 'Computers & Education, 135, 123-137',
    remarks: '(SSCI, 4/263). MOST 105-2511-S-009-006-MY3',
  },
  {
    sid: 6,
    author: 'Lin, SSJ*, & Hsieh, MY',
    year: '2019, May',
    title:
      'Differences between EFL Beginners and Intermediate Level Readers When Reading Onscreen Narrative Text with Pictures: A Study of Eye Movements as a Guide to Personalization',
    journal:
      'International Journal of Human-Computer Interaction, 35(4-5), 299-312',
    remarks: 'SSCI, 13/22',
  },
  {
    sid: 7,
    author: 'Chang, Shan-Mei; Hsieh, Ming-Yi; Lin, SSJ',
    year: '2018, Dec',
    title:
      'The mediation effects of gaming motives between game involvement and problematic Internet use: Escapism, advancement and socializing',
    journal: 'Computers & Education, 122,43-53',
    remarks: 'SSCI, 4/263). MOST 102-2511-S-009-006-MY3',
  },
  {
    sid: 8,
    author: 'Liao, Pei‐Chun; Chen, Ssu‐Kuang; Lin, SSJ',
    year: '2018, Dec',
    title:
      'Latent profiles of stress and their relationships with depression and problematic Internet use among college freshmen',
    journal: 'Scandinavian Journal of Psychology, 59(6),621-630',
    remarks: '(SSCI, 59/138). NSC 101-2631-S-009-001',
  },
  {
    sid: 9,
    author: 'Lin, JH; Lin, SSJ*',
    year: '2018, Jan',
    title:
      'Integrating eye trackers with handwriting tablets to discover difficulties of solving geometry problems',
    journal: 'British Journal of Educational Technology, 49(1),17-29',
    remarks: '(SSCI, 31/263). MOST 102-2511-S-009-005-MY3',
  },
  {
    sid: 10,
    author: 'Chen, S.-K.; Lo, M.-T.; Lin, SSJ',
    year: '2017',
    title:
      'Impulsivity as a precedent factor for problematic Internet use: How can we be sure',
    journal: 'International Journal of Psychology, 52(5), 389-397',
    remarks: '(SSCI, 80/138). NSC 97-2631-S-009-001',
  },
  {
    sid: 11,
    author: 'Chen, Ssu-Kuang; Wang, Lawrence Y.; Liao, Pei-Chun; Lin, SSJ*',
    year: '2017',
    title:
      'Social Internet use and social stress: A perspective of reduction or stimulation of interpersonal relationships',
    journal: '中華心理學刊, 59(22),89-104',
    remarks: ' (TSSCI). MOST 102-2511-S-009-005-MY3',
  },
  {
    sid: 12,
    author: 'Cheng, C.-Y.; Chen, SY; Lin, SSJ*',
    year: '2017',
    title:
      'Episodic and individual effects of elementary students’ optimal experience: An HLM study',
    journal: 'Journal of Educational Research, 110(6), 653-664',
    remarks: '(SSCI, 163/263). MOST 102-2511-S-009-012-MY3.',
  },
  {
    sid: 13,
    author: 'Lo, M.-T.; Chen, S.-K.; Lin, SSJ*',
    year: '2017',
    title:
      'Groups holding multiple achievement goals in the math classroom: Profile stability and cognitive and affective outcomes',
    journal: 'Learning and Individual Differences, 57, 65-76',
    remarks: '(SSCI, 25/60). NSC 100-2511-S-009-007-MY3',
  },
  {
    sid: 14,
    author: 'Chen, S.-K.; Lin, SSJ*',
    year: '2016',
    title:
      'A latent growth curve analysis of initial depression level and changing rate as predictors of problematic Internet use among college students',
    journal: 'Computers in Human Behavior, 54, 380-387',
    remarks: '(SSCI, 12/138). NSC 97-2631-S-009-001',
  },
  {
    sid: 15,
    author: 'Lin, C.-H.; Lee, Y.-H.; Wang, D.-Y.; Lin, SSJ*',
    year: '2016',
    title:
      'Reading Subtitles and Taking Enotes While Learning Scientific Materials in a Multimedia Environment: Cognitive Load Perspectives on EFL Students',
    journal: 'Educational Technology & Society, 19 (4), 47–58',
    remarks: '(SSCI, 83/263). MOST 102-2511-S-009-005-MY3',
  },
  {
    sid: 16,
    author: 'Sun, J. C.-Y., Yu, S.-J., Lin, S. S. J.*,& Tseng, S.-S',
    year: '2016',
    title:
      'The mediating effect of anti-phishing self-efficacy between college students’ Internet self-efficacy and anti-phishing behavior and gender difference.',
    journal: 'Computers in Human Behavior, 59, 249-257',
    remarks: '(SSCI, 12/138).',
  },
  {
    sid: 17,
    author:
      'Hsu, W.-Y., Chang, S.-M., Chiu, N.-Y., Lin, S.S.J.*,& Tseng, I.-S.',
    year: '2015',
    title:
      'Examining application of the DC-IA-A diagnostic criteria for Internet addiction disorder in at-risk college students',
    journal: 'Psychopathology, 48(6):408-416',
    remarks: '(SCIE, 117/155). nstc 97-2631-S-009-001. 本人為通訊作者.',
  },
  {
    sid: 18,
    author:
      'Hsu, W.-Y., Lin, S.S.J.*, Chang, S.-M., Tseng, I.-S.,& Chiu, N.-Y.',
    year: '2015',
    title:
      'Examining the diagnostic criteria for Internet addiction: Expert validation',
    journal: 'Journal of the Formosan Medical Association, 114(6),504-508',
    remarks: '(SCIE, 42/165). nstc 100-2631-S-009-001.',
  },
  {
    sid: 19,
    author: 'Huang, Y.-L., Cheng, C.-Y.,& Lin, S.S.J.*',
    year: '2015',
    title:
      'CIM: Capability-innovation-motive teaching model for system engineering education – “embedded operating systems” as an example',
    journal:
      'International Journal of Automation and Smart Technology, 5(3),151 -161',
    remarks: 'MOST 102-2511-S-009-010-MY3.',
  },
  {
    sid: 20,
    author: 'Lei, P.-L., Sun, C.-T., Lin, S.S.J.*, & Huang, T. K.',
    year: '2015',
    title:
      'Effect of metacognitive strategies and verbal-imagery cognitive style on biology-based video search and learning performance',
    journal: 'Computers & Education, 87, 326-339',
    remarks: '(SSCI, 11/109). MOST 102-2511-S-009-005-MY3.',
  },
  {
    sid: 21,
    author: 'Chang, S.-M., & Lin, S.S.J.*',
    year: '2014',
    title:
      'Team knowledge with motivation in a successful MMORPG game team: A case study',
    journal: 'Computers & Education, 73, 129-140',
    remarks: '(SSCI, 3/243). NSTC 100-2631-S-009-001. 本人為通訊作者.',
  },
  {
    sid: 22,
    author: 'Chen, S.-K., & Lin, S.S.J.*',
    year: '2014',
    title:
      'The latent profiles of life domain importance and satisfaction in a quality of life scale',
    journal: 'Social Indicator Research, 116, 429-445',
    remarks: '(SSCI, 35/104). MOST 97-2631-S-009-001. 本人為通訊作者.',
  },
  {
    sid: 23,
    author: 'Chiang, Y.-T., & Lin, S. S. J.*',
    year: '2014',
    title:
      'The measurement structure, stability and mediating effects of achievement goals in math with middle school student data',
    journal: 'Scandinavian Journal of Educational Research, 58 (5), 513-527',
    remarks: '(SSCI, 126/243). NSTC 99-2631-S-009-001. 本人為通訊作者.',
  },
  {
    sid: 24,
    author: 'Lee, Y.-H., Cheng, C.-Y., & Lin, S.S.J.*',
    year: '2014',
    title:
      'A latent profile analysis of self-control and self-esteem and the grouping effect on adolescent quality of life across two consecutive years',
    journal: 'Social Indicators Research, 117(2), 523–539',
    remarks: '(SSCI, 35/104). MOST 102-2511-S-009-005-MY3. 本人為通訊作者.',
  },
  {
    sid: 25,
    author: 'Lin, C.-H., & Lin, S.S.J.*',
    year: '2014',
    title:
      'Tracking eye movements when solving geometry problems with handwriting devices',
    journal: 'Journal of Eye Movement Research, 7(1):2, 1-15',
    remarks: '(SCIE, 47/59). NSTC 100-2511-S-009-007-MY3. 本人為通訊作者.',
  },
  {
    sid: 26,
    author: 'Lin, J.-H., & Lin, S.S.J.',
    year: '2014',
    title:
      'Cognitive load for configuration comprehension in computer-supported geometry problem solving: an eye movement perspective',
    journal:
      'International Journal of Science and Mathematics Education, 12(3), 605–627',
    remarks: '(SSCI, 173/243). nstc 100-2511-S-009-007-MY3. 本人為通訊作者.',
  },
  {
    sid: 27,
    author: 'Wang, S.-L., Hsu, H.-Y., Lin, S. S. J., & Hwang, G.-J.',
    year: '2014',
    title:
      'The Role of Group Interaction in Collective Efficacy and CSCL Performance',
    journal: 'Educational Technology & Society, 17(4), 242–254',
    remarks: '(SSCI, 55/243). nstc 99-2511-S-011-009-MY3.',
  },
  {
    sid: 28,
    author: 'Chen, S.-K., Hwang, F.-M., & Lin, S. S. J.',
    year: '2013, Jan',
    title:
      'Satisfaction ratings of QOLPAV: Psychometric properties based on the graded response model',
    journal: 'Social Indicators Research, 110, 367-383',
    remarks: '(SSCI). nstc 97-2631-S-009-001. 本人為通訊作者.',
  },
  {
    sid: 29,
    author: 'Chen, S.-K., Yeh, Y. C., Hwang, F.-M., & Lin, S. S. J.',
    year: '2013',
    title:
      'The relationship between academic self-concept and achievement: A multicohort- multioccasion study',
    journal: 'Learning and Individual Differences, 23(1), 172-178',
    remarks: '(SSCI). nstc 97-2631-S-009-001.',
  },
  {
    sid: 30,
    author: 'Lei, P.-L., Lin, S. S. J., & Sun, C.-T.',
    year: '2013',
    title:
      'Effect of reading ability and Internet experience on keyword-based image search',
    journal: 'Educational Technology & Society, 16(2), 151-162',
    remarks: '(SSCI).',
  },
  {
    sid: 31,
    author: 'Lei, P.-L., Lin, S. S. J., Wang, D. Y., & Sun, C. T.',
    year: '2013',
    title:
      'The design of social agents that introduce self-reflection in a simulation environment: A preliminary test',
    journal: 'Educational Technology & Society, 16(3), 152–166',
    remarks: '(SSCI).',
  },
  {
    sid: 32,
    author: 'Lin, C.-H., Chen, S.-K., Chang, S.-M., & Lin, S. S. J.*',
    year: '2013',
    title:
      'Cross-lagged relationships between problematic Internet use and lifestyle changes',
    journal: 'Computers in Human Behavior, 29(6), 2615-2621',
    remarks: '(SSCI). nstc 95-2511-S-009-005-MY3. 本人為通訊作者.',
  },
  {
    sid: 33,
    author: 'Yeh, Y.-C., Lin, S. S. J.*, Tseng, I. S., & Hwang, F.-M.',
    year: '2012',
    title:
      'The questionnaire of Lifestyle Change in Regard to Problematic Internet Use: Factor structure and concurrent and cross-year predictive utilities',
    journal:
      'The Turkish Online Journal of Educational Technology, 11(4), 315-324',
    remarks: '(SSCI). nstc 97-2631-S-009-001. 本人為通訊作者.',
  },
  {
    sid: 34,
    author: 'Chen, S. K., Hwang, F. M., Yeh, Y. C., & Lin, S. S. J.*',
    year: '2012',
    title:
      'Cognitive ability, academic achievement and academic self-concept: Extending the internal/external frame of reference model',
    journal: 'British Journal of Educational Psychology., 82(2), 308-326',
    remarks: '(SSCI). nstc 97-2601-S-009-001',
  },
  {
    sid: 35,
    author: 'Chiang, Y.-T., Lin, S. S. J.*, & Liu, E.Z.F.',
    year: '2012',
    title:
      'The effects of online discussion forum aggressive messages and cognitive distortion on users’ negative affect and aggression',
    journal:
      'The Turkish Online Journal of Educational Technology, 11(2), 238-245',
    remarks: '(SSCI). nstc 97-2631-S-009-001. 本人為通訊作者.',
  },
  {
    sid: 36,
    author: 'Chiang, Y. T., Lin, S. S. J.*, Cheng, C. Y., & Liu, E. Z. F.',
    year: '2011',
    title:
      'Exploring online game players’ flow experiences and positive affect',
    journal:
      'The Turkish Online Journal of Educational Technology, 10(1) ,106-114',
    remarks: '(SSCI). nstc 96-2520-S-009-006-MY3. 本人為通訊作者.',
  },
  {
    sid: 37,
    author: 'Chiang, Y. T., Yeh, Y. C., Lin, S. S. J.*,& Hwang, F. M.',
    year: '2011',
    title:
      'Factor structure and predictive utility of the 2×2 achievement goal model in a sample of Taiwan students',
    journal: 'Learning and Individual Differences., 21, 432-437',
    remarks: '(SSCI). nstc 97-2631-S-009-001. 本人為通訊作者.',
  },
  {
    sid: 38,
    author: 'Chiang,Y.-T., & Lin, S. S. J.*',
    year: '2010',
    title:
      'Early adolescent players’ playfulness and psychological needs in online games',
    journal: 'Social Behaviors and Personality, 38(5), 627-636',
    remarks: '(SSCI). nstc 98-2631-S-009-001. 本人為通訊作者.',
  },
  {
    sid: 39,
    author: 'Lei, P.-L., Kao, G. Y. M., Lin, S. S. J.*,& Sun, C. T.',
    year: '2009',
    title:
      'Impacts of geographical knowledge, spatial ability and environmental cognition on image searches supported by GIS software',
    journal: 'Computers in Human Behaviors, 25(6), 2070-2099',
    remarks: '(SSCI). nstc 98-2631-S-009-001.',
  },
  {
    sid: 40,
    author: 'Kao, G. Y. M., Lin, S. S. J.*,& Sun, C. T.',
    year: '2008',
    title:
      'Beyond Sharing: Engaging students in cooperative and competitive active learning',
    journal: 'Educational Technology & Society, 11(3), 82–96.',
    remarks: '(SSCI). nstc 97-2631-S-009-001. 本人為通訊作者.',
  },
  {
    sid: 41,
    author: 'Kao, G. Y. M., Lin, S. S. J.*, & Sun, C. T.',
    year: '2008',
    title:
      'Breaking concept boundaries to enhance creative potential: Using integrated concept maps for conceptual self-awareness',
    journal: 'Computers and Education, 51(4), 1718-1728',
    remarks: '(SSCI). nstc 97-2631-S-009-001.',
  },
  {
    sid: 42,
    author: 'Liu, E. Z. F., &Lin, S. S. J.*',
    year: '2007',
    title: 'Relationship between peer feedback, cognitive and metacognitive',
    journal:
      'British Journal of Educational Technology, Vol 38 No 6, P.1122-P.1125',
    remarks: '(SSCI).',
  },
  {
    sid: 43,
    author: 'Wang, S. L., & Lin, S. S. J.*',
    year: '2007',
    title:
      'The application of social cognitive theory to Web-based learning through NetPorts.',
    journal: 'British Journal of Educational Technology., 38(4), 600-612.',
    remarks: '(SSCI).',
  },
  {
    sid: 44,
    author: 'Wang, D. Y., Lin, S. S. J.*, & Sun, C. –T.',
    year: '2007',
    title:
      'DIANA：A computer-supported heterogeneous grouping system for teachers to conduct successful small learning groups.',
    journal: 'Computers in Human Behavior., 23(4), 1997-2010.',
    remarks: '(SSCI).',
  },
  {
    sid: 45,
    author: 'Wang, S. L., & Lin, S. S. J.*',
    year: '2007',
    title:
      'The effects of group composition of self-efficacy and collective efficacy on computer-supported collaborative learning.',
    journal: 'Computers in Human Behavior, 23(5), 2256-2268.',
    remarks: '(SSCI).',
  },
  {
    sid: 46,
    author: 'Dai-Yi Wang, Sunny S. J. Lin*,& Chuen-Tsai Suna',
    year: '2006',
    title:
      'Computer-Supported group composing system: Help teachers to form balanced teams and avoid rare dream teams',
    journal: 'WSEAS Transactions on Computers, 5(1), 55-60',
    remarks: '(SCI)',
  },
  {
    sid: 47,
    author: 'Lin, S. S. J., & Liu, E. Z. F.',
    year: '2004',
    title:
      'Effectively accumulating students’ thinking styles via the World Wide Web: Experiences of construction and application',
    journal: 'International Journal of Instructional Media, 30(1), 61-66',
    remarks: '(其它). nstc 93-2520-S-009-006',
  },
  {
    sid: 48,
    author: 'Sun, C. –T., & Lin, S. S. J.*',
    year: '2004',
    title:
      'CORAL-View: A network based design environment for collaborative learning',
    journal: 'International Journal of Instructional Media, 31(2), 151-166',
    remarks: '(其它). nstc 93-2520-S-009-006',
  },
  {
    sid: 49,
    author: 'Tsai, C.-C., & Lin,. S. S. J.*',
    year: '2003',
    title: 'Internet addiction of adolescents in Taiwan: An interview study',
    journal: 'Cyberpsychology and Behavior, 6(6), 649-652',
    remarks: '(SSCI). nstc 90-2511-S-009-006',
  },
  {
    sid: 50,
    author: 'Lin, S. S. J.*, Liu, E. Z. F., & Yuan, S. –M.',
    year: '2002',
    title:
      'Student attitudes toward networked peer assessment: Case studies of undergraduate students and senior high school students',
    journal: 'International Journal of Instructional Media, 29(2), 241-254',
    remarks: '(其它)',
  },
  {
    sid: 51,
    author: 'Liu, E. Z. F., Lin, S. S. J.*, & Yuan, S. –M.',
    year: '2002',
    title:
      'Alternatives to instructor assessment: A case study of comparing self and peer assessment with instructor assessment under networked innovative assessment procedures',
    journal: 'International Journal of Instructional Media, 29(4), 1-10',
    remarks: '(其它). nstc 89-2520-S-009-016.',
  },
  {
    sid: 52,
    author: 'Tsai, C. –C., Lin, S. S. J.*, Yuan, S. –M.',
    year: '2002',
    title:
      'Developing science activities through a networked peer assessment system',
    journal: 'Computers and Education, 38, 241-252',
    remarks: '(SSCI). nstc 89-2520-S-009-001.',
  },
  {
    sid: 53,
    author: 'Lin, S. S. J.*, & Tsai, C. –C.',
    year: '2002',
    title:
      'Sensation seeking and internet dependence of Taiwanese high school adolescents',
    journal: 'Computers in Human Behavior, 18, 411-426',
    remarks: '(SSCI). nstc 89-2520-S-009-010.',
  },
  {
    sid: 54,
    author: 'Lin, S. S. J.*, Sun, C. T., & Kao, G.',
    year: '2002',
    title: 'Designing a networked-sharing construction environment',
    journal: 'British Journal of Educational Technology, 33(4), 489-492',
    remarks: '(SSCI).',
  },
  {
    sid: 55,
    author: 'Tsai, C. –C., Lin, S. S. J.*, & Tsai, M.–J.',
    year: '2001',
    title: 'Developing an Internet attitude scale for high school students',
    journal: 'CyberPsychology and Behavior, 4(3), 373-376',
    remarks: '(SSCI). nstc 89-2511-S-009-007-N.',
  },
  {
    sid: 56,
    author: 'Lin, S. S. J.*, Liu, E. Z. F., & Yuan, S. –M.',
    year: '2001',
    title:
      'Web-based peer assessment: feedback for students with various thinking-styles.',
    journal: 'Journal of Computer Assisted Learning, 17(4), 420-432',
    remarks: '(SSCI). nstc 89-2520-S-009-001.',
  },
  {
    sid: 57,
    author: 'Lin, S. S. J.*, Liu, E. Z. F., & Yuan, S. -M.',
    year: '2001',
    title: 'Web-based peer assessment: Does attitude influence achievement?',
    journal: 'IEEE Transactions on Education, 44(2), 211.',
    remarks: '(EI). nstc 89-2520-S-009-001.',
  },
  {
    sid: 58,
    author: 'Liu, E. Z. F., Lin, S. S. J.*, & Yuan, S.–M.',
    year: '2001',
    title:
      'A World Wide Web Bulletin Board System designed for collaborative learning: Detailed view on system implementation.',
    journal: 'Journal of Internet Technology, 2(2), 129-135.',
    remarks: '(其它). nstc 88-2511-S-009-007.',
  },
  {
    sid: 59,
    author: 'Liu, E. Z. F., Lin, S. S. J.*, & Yuan, S. –M.',
    year: '2001',
    title: 'Design of a networked portfolio system.',
    journal: 'British Journal of Educational Technology, 32(4), 492-494.',
    remarks: '(SSCI). nstc 88-2511-S-009-007.',
  },
  {
    sid: 60,
    author: 'Liu, E. Z. F., Lin, S. S. J.*, Chiu, C. H., & Yuan, S. –M.',
    year: '2001',
    title: 'Web-based peer review: The learner as both adapter and reviewer.',
    journal: 'IEEE Transactions on Education, 44(3), 246-251.',
    remarks: '(EI). nstc 88-2511-S-009-007.',
  },
  {
    sid: 61,
    author: 'Liu, E. Z. F., Lin, S. S. J.*, Wang, W. R., & Yuan, S. –M.',
    year: '2001',
    title: 'Albatross III: A WWW-based course support system.',
    journal: 'International Journal of Instructional Media, 28(4), 363-374',
    remarks: '(其它).',
  },
  {
    sid: 62,
    author: 'Tsai, C. –C., Lin, S. S. J.*, & Yuan, S. –M.',
    year: '2001',
    title:
      'Students’ use of a web-based concept map testing and strategies for learning.',
    journal: 'Journal of Computer Assisted Learning, 17(1), 72-84',
    remarks: '(SSCI).',
  },
  {
    sid: 63,
    author: 'Tsai, C. –C., Liu, E. Z. F., Lin, S. S. J.*, & Yuan, S. –M.',
    year: '2001',
    title: 'A networked peer assessment system based on a Vee heuristic.',
    journal:
      'Innovations in Education and Teaching International, 38(3), 220-230',
    remarks: '(SSCI). nstc 88-2511-S-009-007.',
  },
  {
    sid: 64,
    author: 'Tsai, C.-C., & Lin, S. S. J.*',
    year: '2001',
    title:
      'Analysis of attitudes toward computer networks and Internet addiction of Taiwanese adolescents.',
    journal: 'Cyberpsychology and Behavior, 4(3), 373-376',
    remarks: '(SSCI). nstc 90-2511-S-009-006.',
  },
  {
    sid: 65,
    author: 'Tsai, C. –C., Lin, S. S. J.*, Yuan, S. –M.',
    year: '2000',
    title:
      'Taiwanese high school science students’ views of using a WWW-based concept map testing system.',
    journal: 'International Journal of Instructional Media, 27(4), 363-368',
    remarks: '(其它).',
  },
]
