// TODOs:
// 學生照片
// 版面

import './Students.css'

function Students() {
  return (
    <div className="cont">
      <h4>目前學生</h4>
      <div className="infos-incumb">
        110學年度
        <div className="flex-withimg">
          <div className="info-incumb">
            <img src="./lin.jpg" className="student-icon" alt="icon"></img>
            <div className="info-child">林芳毅(博)</div>
          </div>
          <div className="info-incumb">
            <img src="./chen-w.jpg" className="student-icon" alt="icon"></img>
            <div className="info-child">陳瑋(博)</div>
          </div>
          <div className="info-incumb">
            <img src="./image5.jpeg" className="student-icon" alt="icon"></img>
            <div className="info-child">官政宏(博)</div>
          </div>
          <div className="info-incumb">
            <img src="./kuo-y.jpg" className="student-icon" alt="icon"></img>
            <div className="info-child">郭育君(碩)</div>
          </div>
          <div className="info-incumb">
            <img src="./chiang.jpg" className="student-icon" alt="icon"></img>
            <div className="info-child">江尉菱(碩)</div>
          </div>
          <div className="info-incumb">
            <img src="./chen-y.jpg" className="student-icon" alt="icon"></img>
            <div className="info-child">陳穎芝(碩)</div>
          </div>
          <div className="info-incumb">
            <img src="./liu.jpg" className="student-icon" alt="icon"></img>
            <div className="info-child">劉巧文(碩)</div>
          </div>
          <div className="info-incumb">
            <img src="./xiang.jpg" className="student-icon" alt="icon"></img>
            <div className="info-child">項于珊(碩)</div>
          </div>
          <div className="info-incumb">
            <img src="./kuo-t.jpg" className="student-icon" alt="icon"></img>
            <div className="info-child">郭庭嫣(碩)</div>
          </div>
          <div className="info-incumb">
            <img src="./luan.jpg" className="student-icon" alt="icon"></img>
            <div className="info-child">欒晴宇(碩)</div>
          </div>
        </div>
      </div>
      <hr />
      <h4>畢業學生</h4>
      博士班
      <div className="flex-withimg">
        <div className="info-incumb">
          <img src="./yutzu.jpeg" className="student-icon" alt="icon"></img>
          <div className="info-child">江羽慈</div>
        </div>
        <div className="info-incumb">
          <img src="./yeh.jpeg" className="student-icon" alt="icon"></img>
          <div className="info-child">葉妤貞</div>
        </div>
        <div className="info-incumb">
          <img src="./lin-ch.jpeg" className="student-icon" alt="icon"></img>
          <div className="info-child">林志鴻</div>
        </div>
        <div className="info-incumb">
          <img src="./cheng.jpeg" className="student-icon" alt="icon"></img>
          <div className="info-child">鄭朝陽</div>
        </div>
        <div className="info-incumb">
          <img src="./hsieh.jpeg" className="student-icon" alt="icon"></img>
          <div className="info-child">謝明宜</div>
        </div>
        <div className="info-incumb">
          <img src="./image48.png" className="student-icon" alt="icon"></img>
          <div className="info-child">常善媚</div>
        </div>
        <div className="info-incumb">
          <img src="./hsiao.jpeg" className="student-icon" alt="icon"></img>
          <div className="info-child">蕭若綺</div>
        </div>
      </div>
      <hr />
      <div>碩士班</div>
      <div className="flex-grad">
        <div className="infos-grad">
          101學年度
          <ul>
            <li>林毓晏</li>
            <li>廖芝瑩</li>
            <li>李岱芬</li>
          </ul>
        </div>
        <div className="infos-grad">
          102學年度
          <ul>
            <li>陳瑋</li>
            <li>廖珮君</li>
            <li>歐陽遠</li>
            <li>徐昀</li>
          </ul>
        </div>
        <div className="infos-grad">
          103學年度
          <ul>
            <li>徐逸晴</li>
            <li>莊閔茹</li>
          </ul>
        </div>
        <div className="infos-grad">
          104學年度
          <ul>
            <li>邱宜</li>
            <li>張家約</li>
          </ul>
        </div>
        <div className="infos-grad">
          105學年度
          <ul>
            <li>洪懷鴻</li>
          </ul>
        </div>
        <div className="infos-grad">
          107學年度
          <ul>
            <li>李念錡</li>
            <li>官政宏</li>
          </ul>
        </div>
        <div className="infos-grad">
          108學年度
          <ul>
            <li>陳冠景</li>
            <li>黃薾萱</li>
          </ul>
        </div>
        <div className="infos-grad">
          109學年度
          <ul>
            <li>張祐瑋</li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Students
