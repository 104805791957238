// import { useState } from 'react'
import './Admin.css'

function Admin() {
  //   const [email, setEmail] = useState("");
  //   const [password, setPassword] = useState("");
  //   const [emailError, setEmailError] = useState("");
  //   const [passwordError, setPasswordError] = useState("");

  // function handleSubmit(event) {
  //   event.preventDefault();

  //   // 驗證 email 和 password
  //   let isValid = true;
  //   if (!email) {
  //     setEmailError("Please provide a valid email.");
  //     isValid = false;
  //   }
  //   if (!password) {
  //     setPasswordError("Please provide a valid password.");
  //     isValid = false;
  //   }

  //   // 若驗證通過則提交表單
  //   if (isValid) {
  //     console.log("Form submitted!");
  //   }
  // }

  // function handleEmailChange(event) {
  //   setEmail(event.target.value);
  //   setEmailError("");
  // }

  // function handlePasswordChange(event) {
  //   setPassword(event.target.value);
  //   setPasswordError("");
  // }

  return (
    <div className="cont">
      <div className="statement">***工事中***</div>
      <form
        className="needs-validation"
        noValidate
        // onSubmit={handleSubmit}
      >
        <div className="form-group">
          <label htmlFor="exampleInputEmail1">Email address</label>
          <input
            type="email"
            className="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            placeholder="Enter email"
            // value={email}
            // onChange={handleEmailChange}
            required
          />
          {/* {emailError &&
            <div class="invalid-feedback">Please provide a valid email.</div>
          } */}
          {/* <small id="emailHelp" className="form-text text-muted">
            We'll never share your email with anyone else.
          </small> */}
        </div>
        <div className="form-group">
          <label htmlFor="exampleInputPassword1">Password</label>
          <input
            type="password"
            className="form-control"
            id="exampleInputPassword1"
            placeholder="Password"
            // value={password}
            // onChange={handlePasswordChange}
          />
        </div>
        {/* {passwordError && */}
        {/* <div class="invalid-feedback">Please provide a valid password.</div> */}
        {/* } */}
        <button type="submit" className="btn btn-primary">
          Submit
        </button>
      </form>
    </div>
  )
}

export default Admin
