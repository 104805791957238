import Mainpage from './mainpage/Mainpage'
import Students from './mainpage/Students'
import Admin from './mainpage/Admin';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Navbar from './mainpage/parts/Navbar'
import './App.css'

function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route path="/" element={<Mainpage />} />
        <Route path="/Students" element={<Students />} />
        <Route path="/Admin" element={<Admin />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
