export const NstcProj = [
  {
    proj_id: 1,
    proj_name:
      '01 《國高中生閱讀理解網際網路提供的各類圖文及相關問題解決: 學習動機、線上-離線認知歷程及注視驅動鷹架－子計畫一:科學主題圖文之數位閱讀註記及眼球驅動支援:知識獲取歷程與學習效果》 (MOST) 計畫主持人 2013/08/01~2016/07/31',
  },
  {
    proj_id: 2,
    proj_name:
      '02 《國高中生閱讀理解網際網路提供的各類圖文及相關問題解決: 學習動機、線上-離線認知歷程及注視驅動鷹架－總計畫:國高中生閱讀理解網際網路提供的各類圖文及相關問題解決: 學習動機、線上-離線認知歷程及注視驅動鷹架》 (MOST) 共同主持人 2013/08/01~2016/07/31',
  },
  {
    proj_id: 3,
    proj_name:
      '03 《遠距偏鄉課業輔導教學策略、師生以及環境之探究與評鑑》 (MOST) 共同主持人2013/08/01~2016/07/31',
  },
  {
    proj_id: 4,
    proj_name:
      '04 《系統工程教育之創意課程設計與評量－系統工程創意基礎課程:控制系統與生活化創意專題設計》共同主持人(MOST) 2013/12/01~2016/11/30',
  },
  {
    proj_id: 5,
    proj_name:
      '05 《系統工程教育之創意課程設計與評量－在系統工程課程提升專題導向式學習之創意動機並建立創意評量模組》 (MOST) 計畫主持人 2013/12/01~2016/11/30',
  },
  {
    proj_id: 6,
    proj_name:
      '06 《101-102資訊教育學門研究規劃推動計畫》 (MOST) 計畫主持人 2012/01/01~2013/12/31',
  },
  {
    proj_id: 7,
    proj_name:
      '07 《103-104資訊教育學門研究規劃推動計畫》 (MOST) 計畫主持人 2013/01/01~2014/12/31',
  },
  {
    proj_id: 8,
    proj_name:
      '08 《以數位編輯將新媒體導入教室：科技支援的選擇、建構與互動學習-子計畫一：編輯我的串媒體課本: 學習歷程-成效-動機的眼睛與心智證據》 (MOST) 計畫主持人 2016/08/01~2019/07/31',
  },
  {
    proj_id: 9,
    proj_name:
      '09《系統工程教育4.0：強化產業鏈結與跨領域教師合作之創新人才培育模式-開發SEE4.0創意微學分及持續誘發團隊創意之教學歷程回饋模組》 (MOST) 計畫主持人 2016/12/01~2019/11/30',
  },
  {
    proj_id: 10,
    proj_name:
      '10《大學招生專業化發展試辦計畫》(MOE) 計畫主持人 2017/10/01~2018/09/30',
  },
  {
    proj_id: 11,
    proj_name:
      '11 《應用深度學習與物聯網技術開拓數位教學與學習的可視性：辨識學習者表情與生理訊息並設計可調節即時回饋模組用於三類數位學習系統-總計畫及子計畫三：混成學習教室之自動化表情辨識、生理量測以加值為教師即時回饋模組與學習心理證據》 (MOST) 計畫主持人 2018/08/01~2021/07/31',
  },
  {
    proj_id: 12,
    proj_name:
      '12 《大學招生專業化發展試辦計畫》(MOE) 計畫主持人 2018/10/01~2019/09/30',
  },
  {
    proj_id: 13,
    proj_name:
      '13 《109年中小學數位學習深耕推動計畫-子計畫三》(MOE) 計畫主持人 2020/01/01~2020/12/31',
  },
  {
    proj_id: 14,
    proj_name:
      '14 《110年中小學數位學習深耕推動計畫-子計畫三》(MOE) 計畫主持人 2021/01/01~2021/12/31',
  },
  {
    proj_id: 15,
    proj_name:
      '15 《國高中STEAM課堂多元類型資料的學習分析: 觀課、自動分析與視覺回饋對新手與資深教師專業成長的效果》計畫主持人(NSTC) 2022/08/01~2025/07/31',
  },
  {
    proj_id: 16,
    proj_name:
      '16 《發展素養導向的國中資訊科技「未來課堂」：以學習經驗測試帶動課程、教學模式與評量之設計-子計畫一：國中科技課堂的教學數據分析與回饋系統：以課室觀察與行動型眼動裝置檢驗教學事件對注意力與情緒經驗的長期效果》計畫主持人(MOST) 2019/08/01~2022/07/31',
  },
  {
    proj_id: 17,
    proj_name:
      '17 《國高中生閱讀理解網際網路提供的各類圖文及相關問題解決: 學習動機、線上-離線認知歷程及注視驅動鷹架－子計畫一:科學主題圖文之數位閱讀註記及眼球驅動支援:知識獲取歷程與學習效果》 (MOST) 計畫主持人2013/08/01~2016/07/31',
  },
  {
    proj_id: 18,
    proj_name:
      '18 《將自動化表情辨識及學習回饋系統推動到大學工程課堂翻轉教室之延伸研究及學習數據分析》計畫主持人(MOST) 2021/08/01~2022/07/31',
  },
  {
    proj_id: 19,
    proj_name:
      '19 《國高中生閱讀理解網際網路提供的各類圖文及相關問題解決: 學習動機、線上-離線認知歷程及注視驅動鷹架－子計畫一:科學主題圖文之數位閱讀註記及眼球驅動支援:知識獲取歷程與學習效果》 (MOST) 計畫主持人2013/08/01~2016/07/31',
  },
  {
    proj_id: 20,
    proj_name:
      '20 《台灣學生網路使用及身心適應:追蹤分析及線上事實銀行擴充（單一整合型計畫）-台灣學生網路使用及身心適應:追蹤分析及線上事實銀行擴充（2/2）》林珊如（本校特聘教授） 計畫主持人(NSC-101-2631-S-009-001-)',
  },
  {
    proj_id: 21,
    proj_name:
      '21 《多媒體與遊戲環境中閱讀靜動態數學與科學圖像分析:心智模式、認知負荷與眼球移動分析》林珊如（本校特聘教授） 計畫主持人(NSC-100-2511-S-009-007-MY3)',
  },
  {
    proj_id: 22,
    proj_name:
      '22 《台灣學生網路使用及身心適應:追蹤分析及線上事實銀行擴充（單一整合型計畫）–台灣學生網路使用及身心適應:追蹤分析及線上事實銀行擴充（1/2）》林珊如（本校特聘教授） 計畫主持人(NSC-100-2631-S-009-001-)',
  },
  {
    proj_id: 23,
    proj_name:
      '23 《台灣學生網路使用及身心適應資料庫之建立（3/3） 林珊如（本校特聘教授） 計畫主持人(NSC-99-2631-S-009-001)',
  },
  {
    proj_id: 24,
    proj_name:
      '24 《輔助線上延伸閱讀之搜尋與註記: 學習科技如何促進與阻礙科學文章之理解與想像》林珊如（本校特聘教授）計畫主持人(NSC99-2511-S-009-010-MY3)',
  },
  {
    proj_id: 25,
    proj_name:
      '25 《台灣學生網路使用及身心適應資料庫之建立（2/3）》林珊如（本校特聘教授） (NSC-98-2631-S-009-001)',
  },
  {
    proj_id: 26,
    proj_name:
      '26 《台灣學生網路使用及身心適應資料庫之建立（1/3）》林珊如（本校特聘教授）計畫主持人(NSC-97-2631-S-009-001)',
  },
  {
    proj_id: 27,
    proj_name:
      '27 《以一日經驗重建法探討遊戲玩家與遊戲設計者的心流與情緒》林珊如（本校特聘教授）計畫主持人(NSC-96-2520-S-009-006-MY3)',
  },
  {
    proj_id: 28,
    proj_name:
      '28 《台灣地區學生身心發展之研究》林珊如（本校特聘教授）計畫(95-24-A-2-01-00-2-14)',
  },
  {
    proj_id: 29,
    proj_name:
      '29 《大學生網路科技創造力歷程的自我覺察之研究：對抗慣性思考及有損創造力的因子(2/3)》林珊如(NSC 94-2520-S-009-005)',
  },
  {
    proj_id: 30,
    proj_name:
      '30 《網路文字攻擊誘發歷程之實驗與減低攻擊的認知行為訓練方案(1/2)林珊如*、劉旨峰(NSC 94-2520-S-009-006)',
  },
  {
    proj_id: 31,
    proj_name:
      '31 《大學生網路科技創造力歷程的自我覺察之研究：對抗慣性思考及有損創造力的因子(1/3)》林珊如(NSC 93-2520-S-009-006)',
  },
  {
    proj_id: 32,
    proj_name:
      '32 《網路論戰與敵意之研究：認知歷程、情境線索與自動化文章敵意分級系統(2/2)》林珊如*、劉旨峰(NSC 93-2520-S-009-001)',
  },
  {
    proj_id: 33,
    proj_name:
      '33 《網路合作設計中社群建構知識的研究:創造力與高層次思考在自然與科技領域之開展(3/3)》林珊如(NSC 92-2520-S-009-004)',
  },
  {
    proj_id: 34,
    proj_name:
      '34 《路論戰與敵意之研究：認知歷程、情境線索與自動化文章敵意分級系統(1/2)》林珊如*、劉旨峰(NSC 92-2520-S-009-005)',
  },
  {
    proj_id: 35,
    proj_name:
      '35 《網路合作設計中社群建構知識的研究:創造力與高層次思考在自然與科技領域之開展(2/3)》林珊如(NSC 91-2520-S-009-009)',
  },
  {
    proj_id: 36,
    proj_name:
      '36 《網路合作設計中社群構知識的研究：創造力與高層思考在自然與科技領域之開展(1/3)》林珊如(NSC 90-2520-S-009-003)',
  },
  {
    proj_id: 37,
    proj_name:
      '37 《青少年網路使用者之去社會抑制行為及口語喧染之研究》林珊如(NSC 90-2511-S009-006)',
  },
  {
    proj_id: 38,
    proj_name:
      '38 《網路合作設計科學活動之環境、學習、與評估總計畫。子計畫三：網路合作設計環境及社群設計科學活動的知識建構之評估(3/3)》林珊如(NSC 89-2520-S-009-016)',
  },
  {
    proj_id: 39,
    proj_name:
      '39 《我國學生電腦網路成癮現象與因應策略之研究總計畫。子計畫二：我國高中生網路沈迷與青少年心理特質、媒體使用之研究》林珊如*、周倩(NSC 89-25-S-009-010)',
  },
  {
    proj_id: 40,
    proj_name:
      '40 《網路合作設計科學活動之環境、學習、與評估總計畫。子計畫三：網路合作設計環境及社群設計科學活動的知識建構之評估(2/3)》林珊如(NSC 89-2520-S-009-001)',
  },
  {
    proj_id: 41,
    proj_name:
      '41 《我國學生電腦網路沈迷現象之整合研究。子計畫三：中學生網路沈迷現象之深度訪談研究(2/2)》林珊如*、蔡今中(NSC 89-2511-S-009-007-N)',
  },
  {
    proj_id: 42,
    proj_name:
      '42 《我國大學生之學習動機及學習策略之研究》林珊如*、王淑玲(NSC 89-2413-H-011-001-S)',
  },
  {
    proj_id: 43,
    proj_name:
      '43 《網路合作設計科學活動之環境、學習、與評估。子計畫三：網路合作設計環境及社群設計科學活動的知識建構之評估》林珊如(NSC 88-2511-S-009-007)',
  },
  {
    proj_id: 44,
    proj_name:
      '44 《我國國民中學教師的專業知能的成長階段(II)：專家教師、一般教師與新進教師的區別與觀察研究》林珊如(NSC 87-2413-H-009-001)',
  },
  {
    proj_id: 45,
    proj_name:
      '45 《我國學生電腦網路沈迷現象之整合研究。子計畫三：中學生網路沈迷現象之深度訪談研究》林珊如*、蔡今中(NSC 87-2511-S-009-014-N)',
  },
  {
    proj_id: 46,
    proj_name:
      '46 《我國國民中學教師的專業知能的成長階段(I)：專家教師、一般教師與新進教師的區別與觀察研究》林珊如(NSC 86-2417-H-009-004)',
  },
]
